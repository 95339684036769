import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavComponent } from './nav/nav.component';
import { CommComponent } from './comm/comm.component';
import { CalcComponent } from './calc/calc.component';
import { PlaneComponent } from './plane/plane.component';

const routes: Routes = [
  { path: 'navplan', component: NavComponent },
  { path: 'commplan', component: CommComponent },
  { path: 'calculations', component: CalcComponent },
  { path: 'plane', component: PlaneComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
