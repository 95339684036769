<div class="container">
    <form>
      <mat-form-field class="example-full-width">
        <mat-label>Wind Direction</mat-label>
        <input type="number" matInput id="winddirection" (change)="onDirectionChange($event.target.value)" [(ngModel)]='model.winddirection' name='direction'>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label>Wind Speed</mat-label>
        <input type="number" matInput id="windspeed" (change)="onSpeedChange($event.target.value)" [(ngModel)]='model.windspeed' name='speed'>
      </mat-form-field>   
    </form>
</div>