<mat-grid-list cols="2" rowHeight="2:1">
  <mat-grid-tile>
  	<mat-card>
  	  <mat-card-header>
    	<mat-card-title>Fuel</mat-card-title>
    	<mat-card-subtitle>Fuel Consumption per hour </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
  		Simple card 1
  	  </mat-card-content>
  	</mat-card>
  </mat-grid-tile>
  <mat-grid-tile><mat-card>Simple card 2</mat-card></mat-grid-tile>
  <mat-grid-tile><mat-card>Simple card 3</mat-card></mat-grid-tile>
  <mat-grid-tile><mat-card>Simple card 4</mat-card></mat-grid-tile>
</mat-grid-list>