<div class="example-container">
  <h2>RT blokken</h2>

  <div
    cdkDropList
    #optionsList="cdkDropList"
    [cdkDropListData]="options"
    [cdkDropListConnectedTo]="[planList]"
    class="example-list"
    >
    <div class="example-box" *ngFor="let item of options" cdkDrag>{{item}}</div>
  </div>
</div>

<div class="complan-container">
  <h2>RT plan</h2>

  <div
    cdkDropList
    #planList="cdkDropList"
    [cdkDropListData]="selected"
    class="complan-list"
    (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let item of selected" cdkDrag>{{item}}</div>
  </div>
</div>