import { Component, OnInit } from '@angular/core';
import { Conmmplanrow } from './complanrow';
import { CdkDragDrop, moveItemInArray, copyArrayItem} from '@angular/cdk/drag-drop';
import { DataService } from '../data.service';
import { fpboard } from '../plan';
import {DialogComponent} from "./dialog.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

@Component({
  selector: 'app-comm',
  templateUrl: './comm.component.html',
  styleUrls: ['./comm.component.css']
})
export class CommComponent implements OnInit {
	rtBlokken;
	
	options = [
		"Departure uncontrolled field",
		"Departure controlled field",
		"Duch Mil Info",
		"Amsterdam Information",
		"Crossing CTR",
		"Arrival uncontrolled field",
		"Arrival controlled field"
	];

	selected = [
	];
 	constructor(dataservice: DataService,private dialog: MatDialog) {
 		this.dataservice = dataservice;
 		var localselected = this.selected;
 		this.complan = this.dataservice.getComPlan();
 	    this.navplan = this.dataservice.getNavPlan();
 	    if(this.complan.comrows !== undefined){
	 	    this.complan.comrows.forEach(function(value){
	 	    	localselected.push(value.text);
	 	    });
 	    }else{
 	    	this.complan = new fpboard.Complan();
 	    }
 	   this.selected = localselected;
 	}
 	
 	private complan : fpboard.Complan;
    private navplan : fpboard.Navplan;
 	private dataservice: DataService;
 	private taglist = new Array();
 	private tagindex = 0;
 	private taginfo = new Map();
 	
  	ngOnInit() { 
  		this.rtBlokken = new Map();
  		this.rtBlokken.set("Departure uncontrolled field", "-> {{from airodrome}} radio {{plane registration}} radio check {{departure frequency}}\n" +
  				"<- {{plane registration}} reading you 5 runway in use ...,  ... circuit\n" +
  				"-> reading you five runway in use ..., ..... circuit, {{name of pilots}}, VFR {{from airodrome}} to {{to airodrome}}\n" +
  				"-> {{plane registration}} lining up runway ...\n");
  		this.rtBlokken.set("Departure controlled field", "Listen to ATIS info on {{departure ATIS frequency}}\n" +
  				"-- ATIS INFO ..., Runway ..., QNH ....\n" +
  				"-> {{from airodrome}} {{delivery/ground}} {{plane registration}}.\n" +
  				"<- {{plane registration}} go ahead.\n" +
  				"-> {{plane registration}} type {{plane type}}, Position {{parking position}} VFR {{from airodrome}} to {{to airodrome}} information ... received. Request startup\n" +
  				"<- {{plane registration}} start-up approved information ... is correct QNH ...hPa, runway in use ..., time .... " +
				"-> start-up approved QNH ...hPa, runway in use ..., time checked {{plane registration}}\n" +
				"<- {{plane registration}} readback is correct for taxi contact {{taxi twr/grnd}}, {{taxi twr/grnd frequency}} squawk ....\n" +
				"-> wilco {{taxi twr/grnd}}, {{taxi twr/grnd frequency}} squawk .... {{plane registration}}\n" +
				"-> {{from airodrome}} {{taxi twr/grnd}} {{plane registration}} TYPE {{plane type}}, Position {{parking position}} request taxi to holdingpoint runway ...\n" +
				"<- {{plane registration}} cleared taxi holdingpoint runway ... via ................\n" +
				"-> cleared taxi holdingpoint runway ... via ................ {{plane registration}}\n" +
				"-> {{plane registration}} position holding runway ... ready for departure.\n" +
				"<- {{plane registration}} line-up runway  ... and hold.\n" +
				"-> linin-up runway ... and holding {{plane registration}}\n" +
				"<- {{plane registration}} cleared for take-off runway ... , ... departure, wind .../...\n" +
				"-> cleared for take-off runway ... , ... departure, Roger , {{plane registration}}\n" +
				"-> {{plane registration}} overhead ... leaving CTR and your frequency for {{switch to dutch mil info/amsterdam information}}\n" +
				"<- {{plane registration}} roger, frequency change is approved {{switch to dutch mil info/amsterdam information frequency}}/squawk VFR\n" +
				"-> frequency change appoved {{switch to dutch mil info/amsterdam information frequency}}/squawk VFR {{plane registration}}");
  		this.rtBlokken.set("Duch Mil Info", "Frequency DUTCH MIL INFO {{frequency Dutch Mil Info}}\n" +
  				"-> DUTCH MIL INFO {{plane registration}}\n" +
  				"<- {{plane registration}} go ahead\n" +
  				"-> {{plane registration}} type {{plane type}}, VFR from {{from airodrome}} to {{to airodrome}}, position {{duthmill position}}, persons on board {{persons on board}} altitude {{dutchmill altitude}} ft ETA {{destination ETA}}\n" +
  				"<- {{plane registration}} regional QNH .... hPa, flight information service, report when leaving\n" +
  				"-> roger, wilco when leaving {{plane registration}}");
  		this.rtBlokken.set("Amsterdam Information", "Frequency DUTCH MIL INFO {{frequency Amsterdam Information}}\n" +
  			"-> AMSTERDAM INFORMATION {{plane registration}}\n"+
  			"<- {{plane registration}} go ahead\n" +
			"-> {{plane registration}} type {{plane type}}, VFR from {{from airodrome}} to {{to airodrome}}, position {{duthmill position}}, persons on board {{persons on board}} altitude {{dutchmill altitude}} ft ETA {{destination ETA}}\n" +
			"<- {{plane registration}} regional QNH .... hPa, flight information service, report when leaving\n" +
  			"-> roger, wilco when leaving {{plane registration}}");
  		this.rtBlokken.set("Crossing CTR", "Frequency {{CTR name}} {{CTR tower/info}}: {{frequency CTR}}\n"+ 
  				"-> {{CTR name}} {{CTR tower/info}}  {{plane registration}}\n"+
  				"<- {{plane registration}} go ahead\n" +
  				"-> {{plane registration}} type {{plane type}}, VFR from {{from airodrome}} to {{to airodrome}}, position {{ctr position}}, request to cross the CTR {{CTR route}} at altitude {{ctr altitude}} ft\n" +
  				"<- {{plane registration}} crossing approved via {{CTR route}} at {{ctr altitude}} ft QNH .... hPa Report when entering the CTR.\n" +
  				"-> Crossing approved via {{CTR route}} at {{ctr altitude}} ft QNH .... hPa, wilco when entering CTR {{plane registration}}\n" +
  				"-> {{plane registration}} over ... entering CTR {{ctr altitude}} ft\n" +
  				"<- roger, report when leaving CTR\n" +
  				"-> wilco when leaving CTR {{plane registration}}\n" +
  				"-> {plane registration}} over ... leaving CTR {{ctr altitude}} ft\n");
  		this.rtBlokken.set("Arrival uncontrolled field", "{{to airodrome}} frequency: {{arrival frequency}}\n" +
  				"-> {{to airodrome}} radio {{plane registration}} request airodrome infromation\n" +
  				"-> {{plane registration}} we have runway ...,  ..... circuit\n" +
  				"<- runway in use ..., ..... circuit {{plane registration}}\n" +
  				"<- {{plane registration}} joining ..... circuit\n" +
  				"<- {{plane registration}} final, runway ...");
  		this.rtBlokken.set("Arrival controlled field", "ATIS Arrival freq {{atis freq}}\n" +
  				"info ...\n" +
  				"runway ...\n" + 
  				"wind ....../...... vis ......\n" +
  				"clouds ...... temp ... / dewpoint ...\n" +
  				"QNH ......\n" +
  				"-> {{tower frequency}} tower {{plane registration}}\n" +
  				"<- {{plane registration}} go ahead\n" +
  				"-> {{plane registration}} type {{plane type}} position {{arrival position}} VFR from {{from airodrome}} to {{to airodrome}} information ... recieved for {{landing/touch and go}}\n" +
  				"<- {{plane registration}} ... arrival runway .. in use information .. correct QNH .... hPa, squawk ......\n" +
  				"-> ... arrival runway ... in use, QNH .... hPa, squawk ...... {{plane registration}}\n" +
  				"<- report...........  -> wilco\n " +
  				"<- {{plane registration}} cleared to {{landing/touch and go}} runway .. wind ....../......" +
  				"-> cleared to land runway .. roger {{plane registration}}\n");
  		this.rtBlokken.set("After landing controlled field", 				
  				"<- {{plane registration}} after landing vacate via .........n" +
  				"-> After landing vacate via .......... {{plane registration}}\n" +
  				"<- {{plane registration}} runway .. vacated request {{taxi/parking}} instructions\n" +
  				"-> {{plane registration}} taxi to ....\n" +
  				"	via...............\n" +
  				"<- taxi to ....\n" +
  				"	via...............{{plane registration}}\n" +
  				"-> {{plane registration}} report {{marshaller/follow me car}} in sight\n" +
  				"<- wilco, when {{marshaller/follow me car}} in sight." +
  				"<- \n" +
  				"<- {{plane registration}} request switch off\n" +
  				"-> {{plane registration}} switching-off approved\n" +
  				"<- Switching off approved {{plane registration}}" 
  				);
  	}

	drop(event: CdkDragDrop<string[]>) {
		if(!event.isPointerOverContainer && event.previousContainer.connectedTo[0]===undefined){
			event.previousContainer.data.splice(event.previousIndex,1);
		}
		if (event.previousContainer === event.container) {
			var localcomplan = this.complan;
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
			var cnt = 0;
			event.container.data.forEach(function (value){
				console.log(value);
								
				const comrow = fpboard.ComRow.create();
				comrow.text = value; 
				localcomplan.comrows[cnt] = comrow;
				cnt++;
			});			 		
			this.dataservice.setComPlan(this.complan);
		} else {
			const navplan = this.dataservice.getNavPlan();			
			const content = this.rtBlokken.get(event.previousContainer.data[event.previousIndex]);
			this.findtags(content);
			this.tagDialog(event,content);
		}
	}

	findtags(content){
		var start = 0;
		var index = 0;		
		while(index>=0){
			index = content.indexOf("{{",start)
			var begin = (index+2);
			if( index != -1){
				var end = content.indexOf("}}",begin);
				var tag = content.substr(begin,end-begin);
				this.taglist.push(tag);
				start = end+2;
				index = end;
			}else{
				break;
			}
		}
	}
	
	replaceTags(content){	
		let localinfo = this.taginfo;
		this.taglist.forEach(function (value) {
			let replace =localinfo.get(value);
			content = content.split('{{'+value+'}}').join(replace);
		}); 
		return content;
	}
	
	tagDialog(event, content){
		var localcomplan = this.complan;
		let tag = this.taglist[this.tagindex];
		while(!(tag===undefined) && !(this.taginfo.get(tag)===undefined)){
			this.tagindex++;
			tag = this.taglist[this.tagindex];
		}
		if(!(tag===undefined) && this.taginfo.get(tag)===undefined){
			const dialogConfig = new MatDialogConfig();
		
	        dialogConfig.disableClose = true;
	        dialogConfig.autoFocus = true;
	        dialogConfig.data = {
	                id: 1,
	                description: tag
	        };
	        let dialogRef = this.dialog.open(DialogComponent, dialogConfig);
	        dialogRef.afterClosed()
	        .subscribe(data => {
	        	console.log(data.description);
	        	this.taginfo.set(tag,data.description);
	        	this.tagindex++;
	        	this.tagDialog(event, content);	        	
	        })
		}else{
			event.container.data.splice(event.currentIndex,0, this.replaceTags(content));
			var cnt = 0;
			event.container.data.forEach(function (value){
				console.log(value);
								
				const comrow = fpboard.ComRow.create();
				comrow.text = value; 
				localcomplan.comrows[cnt] = comrow;
				cnt++;
			});			 			
			this.dataservice.setComPlan(localcomplan);
		}
	}
}
