
<h2 mat-dialog-title>Supply value for '{{description}}'</h2>


<mat-dialog-content [formGroup]="form">

    <mat-form-field>

        <input matInput
                placeholder="Value"
               formControlName="description">

    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>

    <button class="mat-raised-button mat-primary"
            (click)="save()">
        Save
    </button>

</mat-dialog-actions>

