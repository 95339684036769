import { Component, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { DataService } from './data.service';
import { Subscription } from 'rxjs';
import { fpboard } from './plan';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {SyncDialogComponent} from "./syncdialog.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [DataService]
})

export class AppComponent {
  navLinks = [
	  { path: '/navplan', label:  'Navigatieplan VFR'},
	  { path: '/commplan', label:  'Communicatieplan VFR'},
	  { path: '/calculations', label:  'Calculations'},
	  { path: '/plane', label:  'Plane Parameters'}
];
  subscription: Subscription;
  tempplan: string;
  title = 'Navigatieplan VFR';
  activeLink = this.navLinks[0];
  background: ThemePalette = undefined;
  
  private peerConnection: RTCPeerConnection;
  private signalingConnection: WebSocket;
  private sendChannel: RTCDataChannel;
  private uuid;
  constructor(private dataservice: DataService, private http: HttpClient,private dialog: MatDialog) {
	  
  }

  onSync() {
    // create new plan
    console.log( "onsync()");
    let plan = fpboard.Plan.create();
    plan.version='1';
    plan.navplan = this.dataservice.getNavPlan();
    plan.complan[0] = this.dataservice.getComPlan();    
    this.toStorage(plan);
  }

  private toStorage(plan){
	  this.http.post<any>('/php/upload.php', JSON.stringify(plan)).subscribe(data => {
		  this.tempplan = data.data;
		  let planname =  plan.navplan.flightdate+"_"+plan.navplan.fromairodrome+"_"+plan.navplan.toairodrome; 
		  if(planname == "__"){
		      	this.http.get<any>('/php/download.php?key='+this.tempplan).subscribe(data => {
		    		console.log("Download");
					console.log(data);
					this.dataservice.setNavPlan(data.navplan);
					this.dataservice.setComPlan(data.complan[0]);
					this.dialog.closeAll();
		    	})
		  }
		  const dialogConfig = new MatDialogConfig();	    	
	      dialogConfig.disableClose = false;
	      dialogConfig.autoFocus = true;
	      dialogConfig.data = {
	                id: 1,
	                description: planname+"|"+data.data
	       };
	        let dialogRef = this.dialog.open(SyncDialogComponent, dialogConfig);
	    
	        dialogRef.afterClosed()
	        .subscribe(data => {
					this.http.delete('/php/delete.php?key='+this.tempplan).subscribe(data => {
		        		console.log("Delete");
		        		console.log(data);
		        	});	   
	        });
	    })    
  }
  
  onPrint() {
    this.dataservice.printDomlayoutChanged('print');
    setTimeout( _ => {
      print();
      this.dataservice.printDomlayoutChanged('normal');
    }, 2000);
  }
  
  private errorHandler(error) {
    console.log(error);
  }
}
