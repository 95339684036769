import { Component,  ViewEncapsulation } from '@angular/core';

import {FlightInfo} from './footer/flightinfo';
import { DataService } from '../data.service';
import { Subscription } from 'rxjs';
import { fpboard } from '../plan';
import { Navplanrow } from './navplanrow';
@Component({
  selector: 'app-root',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css'],
  encapsulation: ViewEncapsulation.None
})


export class NavComponent {

  constructor(dataservice: DataService) {
    this.dataservice = dataservice;
    this.subscription = dataservice.printDomlayoutChanged$.subscribe(
        prindom => {
            if (prindom === 'print') {
                this.navGridApi.setColumnDefs(this.printcolumnDefs);
            } else {
                this.navGridApi.setColumnDefs(this.columnDefs);
            }
            this.navGridApi.setDomLayout(prindom);
        }
    );
    var counter = 0;
    this.navplan = this.dataservice.getNavPlan();
    if(this.navplan.navrows !== undefined){
	    this.navplan.navrows.forEach(element => {
	    	const npr = new Navplanrow();
	    	npr.itime = element.itime as number;
	    	npr.ttime = element.ttime as number;
	    	npr.waypoint = element.waypoint;
	    	npr.tacvorndb = element.tacvorndb;
	    	npr.frequency = element.frequency;
	    	npr.raddist = element.raddist;
	    	npr.cheading = element.cheading;
	    	npr.deviation = element.deviation;
	    	npr.mheading = element.mheading;
	    	npr.variation = element.variation;
	    	npr.theading = element.theading;
	    	npr.wcangle = element.wcangle;
	    	npr.ttrack = element.ttrack;
	    	npr.idist = element.idist;
	    	npr.tdist = element.tdist;
	    	npr.groundspeed = element.groundspeed;
	    	npr.tas = element.tas;
	    	npr.ias = element.ias;
	    	npr.moca = element.moca;    	
	    	this.rowData[counter] = npr; 
	    	counter++;
	    });
    }else{
    	 this.navplan = new fpboard.Navplan();
    }
    this.recalculate();
  }
  private navGridApi: any;
  private navplan : fpboard.Navplan;
  private dataservice: DataService;
  title = 'Navplan';
  wind: FlightInfo;
  columnDefs = [
    {headerName: 'FlightTime',
            children: [
        {headerName: 'Int', headerTooltip: 'Flighttime Interval', editable: false, field: 'itime', minWidth : 70,
        cellStyle(params: { node: { firstChild: any; }; }) {
            if (params.node.firstChild) {
                // mark police cells as red
                return { backgroundColor: '#e9e9e9'};
            } else {
                return null;
            }
        }
    },
    {headerName: 'Tot', headerTooltip: 'Flighttime Total', field: 'ttime', editable: false, minWidth : 70,
        cellStyle(params: { node: { firstChild: any; }; }) {
        if (params.node.firstChild) {
            // mark police cells as red
            return { backgroundColor: '#e9e9e9'};
        } else {
            return null;
        }
    }}]},
        {headerName: 'Waypoint', headerTooltip: 'Waypoint', field: 'waypoint', minWidth : 170, suppressSizeToFit: true},
        {headerName: 'TAC VOR NDB', headerTooltip: 'TAC VOR NDB', field: 'tacvorndb', minWidth : 70 },
        {headerName: 'Freq', headerTooltip: 'Frequency', field: 'frequency', minWidth : 70 },
        {headerName: 'Rad Dist', headerTooltip: 'Radial Distance', field: 'raddist', minWidth : 70 },
        {headerName: 'Comp Hdg', headerTooltip: 'Compas Heading', field: 'cheading', minWidth : 70, editable: false,
        cellStyle(params: { node: { firstChild: any; }; }) {
            if (params.node.firstChild) {
                // mark police cells as red
                return { backgroundColor: '#e9e9e9'};
            } else {
                return null;
            }
        }
    },
        {headerName: 'Dev', headerTooltip: 'Deviation', field: 'deviation', minWidth : 70,
            editable(params: { node: { firstChild: any; }; }) { return !params.node.firstChild; }
            , cellStyle(params: { node: { firstChild: any; }; }) {
        if (params.node.firstChild) {
            // mark police cells as red
            return { backgroundColor: '#e9e9e9'};
        } else {
            return null;
        }
    } },
        {headerName: 'Magn Hdg', headerTooltip: 'Magnetic Heading', field: 'mheading', minWidth : 70, editable: false,
        cellStyle(params: { node: { firstChild: any; }; }) {
            if (params.node.firstChild) {
                // mark police cells as red
                return { backgroundColor: '#e9e9e9'};
            } else {
                return null;
            }
        }
     },
        {headerName: 'Var', headerTooltip: 'Variation', field: 'variation', minWidth : 70,
            editable(params: { node: { firstChild: any; }; }) {
                return !params.node.firstChild;
            },
            cellStyle(params: { node: { firstChild: any; }; }) {
                if (params.node.firstChild) {
                    // mark police cells as red
                    return { backgroundColor: '#e9e9e9'};
                } else {
                    return null;
                }
            }
        },
        {headerName: 'True Hdg', headerTooltip: 'True Heading', field: 'theading', minWidth : 70, editable: false,
            cellStyle(params: { node: { firstChild: any; }; }) {
                if (params.node.firstChild) {
                    // mark police cells as red
                    return { backgroundColor: '#e9e9e9'};
                } else {
                    return null;
                }
            }
        },
        {headerName: 'Wind Corr Angle', headerTooltip: 'Wind Correction Angle', editable: false, minWidth : 70, field: 'wcangle',
            cellStyle(params: { node: { firstChild: any; }; }) {
                if (params.node.firstChild) {
                    // mark police cells as red
                    return { backgroundColor: '#e9e9e9'};
                } else {
                    return null;
                }
            }
        },
        {headerName: 'True Track', headerTooltip: 'True Track', field: 'ttrack', minWidth : 110, suppressSizeToFit: true,
            editable(params: { node: { firstChild: any; }; }) {return !params.node.firstChild; },
            cellStyle(params: { node: { firstChild: any; }; }) {
                if (params.node.firstChild) {
                    // mark police cells as red
                    return { backgroundColor: '#e9e9e9'};
                } else {
                    return null;
                }
            }
        },
        {headerName: 'Distance',
            children: [
            {headerName: 'Int', headerTooltip: 'Distance Interval', field: 'idist', minWidth : 70,
                editable(params: { node: { firstChild: any; }; }) {return !params.node.firstChild; },
                cellStyle(params: { node: { firstChild: any; }; }) {
                    if (params.node.firstChild) {
                        // mark police cells as red
                        return { backgroundColor: '#e9e9e9'};
                    } else {
                        return null;
                    }
                }
                },
            {headerName: 'Tot', headerTooltip: 'Distance Total', field: 'tdist', minWidth : 70, editable: false,
                cellStyle(params: { node: { firstChild: any; }; }) {
                    if (params.node.firstChild) {
                        // mark police cells as red
                        return { backgroundColor: '#e9e9e9'};
                    } else {
                        return null;
                    }
                }
            }]},
        {headerName: 'GS', headerTooltip: 'Ground Speed', field: 'groundspeed', minWidth : 70, editable: false,
            cellStyle(params: { node: { firstChild: any; }; }) {
                if (params.node.firstChild) {
                    // mark police cells as red
                    return { backgroundColor: '#e9e9e9'};
                } else {
                    return null;
                }
            }
        },
        {headerName: 'TAS', headerTooltip: 'True Air Speed', field: 'tas', minWidth : 70,
            editable(params: { node: { firstChild: any; }; }) {return !params.node.firstChild; },
            cellStyle(params: { node: { firstChild: any; }; }) {
                if (params.node.firstChild) {
                    // mark police cells as red
                    return { backgroundColor: '#e9e9e9'};
                } else {
                    return null;
                }
            }
        },
        {headerName: 'IAS', headerTooltip: 'Indicated Air Speed', field: 'ias', minWidth : 70, editable: false,
            cellStyle(params: { node: { firstChild: any; }; }) {
                if (params.node.firstChild) {
                    // mark police cells as red
                    return { backgroundColor: '#e9e9e9'};
                } else {
                    return null;
                }
            }
        },
        {headerName: 'MOCA', headerTooltip: 'Minimum Obstacle Clearance Altitude', minWidth : 70,
            editable(params: { node: { firstChild: any; }; }) {return !params.node.firstChild; }, field: 'moca',
            cellStyle(params: { node: { firstChild: any; }; }) {
                if (params.node.firstChild) {
                    // mark police cells as red
                    return { backgroundColor: '#e9e9e9'};
                } else {
                    return null;
                }
            }
        },
    ];

    printcolumnDefs = [
    {headerName: 'RETO', field: 'RETO', width : 40,  suppressSizeToFit: true},
    {headerName: 'ETO', field: 'ETO', width : 30, suppressSizeToFit: true},
    {headerName: 'ATO', field: 'ATO', width : 30, suppressSizeToFit: true},
    {headerName: 'FlightTime',
            children: [
        {headerName: 'Int', headerTooltip: 'Flighttime Interval', editable: false, field: 'itime', width : 30, suppressSizeToFit: true,
        cellStyle(params: { node: { firstChild: any; }; }) {
            if (params.node.firstChild) {
                // mark police cells as red
                return { backgroundColor: '#e9e9e9'};
            } else {
                return null;
            }
        }
    },
    {headerName: 'Tot', headerTooltip: 'Flighttime Total', field: 'ttime', editable: false, width : 30, suppressSizeToFit: true,
        cellStyle(params: { node: { firstChild: any; }; }) {
        if (params.node.firstChild) {
            // mark police cells as red
            return { backgroundColor: '#e9e9e9'};
        } else {
            return null;
        }
    }}]},
        {headerName: 'Waypoint', headerTooltip: 'Waypoint', field: 'waypoint', width : 140, suppressSizeToFit: true},
        {headerName: 'TAC VOR NDB', headerTooltip: 'TAC VOR NDB', field: 'tacvorndb', width : 30, suppressSizeToFit: true  },
        {headerName: 'Freq', headerTooltip: 'Frequency', field: 'frequency', width : 60, suppressSizeToFit: true},
        {headerName: 'Rad Dist', headerTooltip: 'Radial Distance', field: 'raddist', width : 30, suppressSizeToFit: true },
        {headerName: 'Comp Hdg', headerTooltip: 'Compas Heading', field: 'cheading', editable: false, width : 30, suppressSizeToFit: true,
        cellStyle(params: { node: { firstChild: any; }; }) {
            if (params.node.firstChild) {
                // mark police cells as red
                return { backgroundColor: '#e9e9e9'};
            } else {
                return null;
            }
        }
    },
        {headerName: 'Dev', headerTooltip: 'Deviation', field: 'deviation', width : 30, suppressSizeToFit: true,
            editable(params: { node: { firstChild: any; }; }) { return !params.node.firstChild; }
            , cellStyle(params: { node: { firstChild: any; }; }) {
        if (params.node.firstChild) {
            // mark police cells as red
            return { backgroundColor: '#e9e9e9'};
        } else {
            return null;
        }
    } },
        {headerName: 'Magn Hdg', headerTooltip: 'Magnetic Heading', field: 'mheading', editable: false, width : 30, suppressSizeToFit: true,
        cellStyle(params: { node: { firstChild: any; }; }) {
            if (params.node.firstChild) {
                // mark police cells as red
                return { backgroundColor: '#e9e9e9'};
            } else {
                return null;
            }
        }
     },
        {headerName: 'Var', headerTooltip: 'Variation', field: 'variation', width : 30, suppressSizeToFit: true,
            editable(params: { node: { firstChild: any; }; }) {
                return !params.node.firstChild;
            },
            cellStyle(params: { node: { firstChild: any; }; }) {
                if (params.node.firstChild) {
                    // mark police cells as red
                    return { backgroundColor: '#e9e9e9'};
                } else {
                    return null;
                }
            }
        },
        {headerName: 'True Hdg', headerTooltip: 'True Heading', field: 'theading', editable: false, width : 30, suppressSizeToFit: true,
            cellStyle(params: { node: { firstChild: any; }; }) {
                if (params.node.firstChild) {
                    // mark police cells as red
                    return { backgroundColor: '#e9e9e9'};
                } else {
                    return null;
                }
            }
        },
        {headerName: 'Wind Corr Angle', headerTooltip: 'Wind Correction Angle', editable: false, field: 'wcangle', width : 40, suppressSizeToFit: true,
            cellStyle(params: { node: { firstChild: any; }; }) {
                if (params.node.firstChild) {
                    // mark police cells as red
                    return { backgroundColor: '#e9e9e9'};
                } else {
                    return null;
                }
            }
        },
        {headerName: 'True Track', headerTooltip: 'True Track', field: 'ttrack', width : 30, suppressSizeToFit: true,
            editable(params: { node: { firstChild: any; }; }) {return !params.node.firstChild; },
            cellStyle(params: { node: { firstChild: any; }; }) {
                if (params.node.firstChild) {
                    // mark police cells as red
                    return { backgroundColor: '#e9e9e9'};
                } else {
                    return null;
                }
            }
        },
        {headerName: 'Distance',
            children: [
            {headerName: 'Int', headerTooltip: 'Distance Interval', field: 'idist', width : 30, suppressSizeToFit: true,
                editable(params: { node: { firstChild: any; }; }) {return !params.node.firstChild; },
                cellStyle(params: { node: { firstChild: any; }; }) {
                    if (params.node.firstChild) {
                        // mark police cells as red
                        return { backgroundColor: '#e9e9e9'};
                    } else {
                        return null;
                    }
                }
                },
            {headerName: 'Tot', headerTooltip: 'Distance Total', field: 'tdist', editable: false, width : 30, suppressSizeToFit: true,
                cellStyle(params: { node: { firstChild: any; }; }) {
                    if (params.node.firstChild) {
                        // mark police cells as red
                        return { backgroundColor: '#e9e9e9'};
                    } else {
                        return null;
                    }
                }
            }]},
        {headerName: 'GS', headerTooltip: 'Ground Speed', field: 'groundspeed', editable: false, width : 30, suppressSizeToFit: true,
            cellStyle(params: { node: { firstChild: any; }; }) {
                if (params.node.firstChild) {
                    // mark police cells as red
                    return { backgroundColor: '#e9e9e9'};
                } else {
                    return null;
                }
            }
        },
        {headerName: 'TAS', headerTooltip: 'True Air Speed', field: 'tas', width : 30, suppressSizeToFit: true,
            editable(params: { node: { firstChild: any; }; }) {return !params.node.firstChild; },
            cellStyle(params: { node: { firstChild: any; }; }) {
                if (params.node.firstChild) {
                    // mark police cells as red
                    return { backgroundColor: '#e9e9e9'};
                } else {
                    return null;
                }
            }
        },
        {headerName: 'IAS', headerTooltip: 'Indicated Air Speed', field: 'ias', editable: false, width : 30, suppressSizeToFit: true,
            cellStyle(params: { node: { firstChild: any; }; }) {
                if (params.node.firstChild) {
                    // mark police cells as red
                    return { backgroundColor: '#e9e9e9'};
                } else {
                    return null;
                }
            }
        },
        {headerName: 'MOCA', headerTooltip: 'Minimum Obstacle Clearance Altitude', width : 40, suppressSizeToFit: true ,
            editable(params: { node: { firstChild: any; }; }) {return !params.node.firstChild; }, field: 'moca',
            cellStyle(params: { node: { firstChild: any; }; }) {
                if (params.node.firstChild) {
                    // mark police cells as red
                    return { backgroundColor: '#e9e9e9'};
                } else {
                    return null;
                }
            }
        },
    ];
    defaultColDef = { editable : true, resizable : true};
    rowData = [ new Navplanrow(),
                new Navplanrow(),
                new Navplanrow(),
                new Navplanrow(),
                new Navplanrow(),
                new Navplanrow(),
                new Navplanrow(),
                new Navplanrow(),
                new Navplanrow(),
                new Navplanrow(),
                new Navplanrow(),
                new Navplanrow()];

  
    subscription: Subscription;

    degreesToRads = (deg: number) => (deg * Math.PI) / 180.0;

    onGridReady(params) {
        this.navGridApi = params.api;
      //  this.navGridApi.sizeColumnsToFit();
        // todo remove after debug
  //      this.navGridApi.setDomLayout('print');
    }

    onGridSizeChanged(params: any) {
        this.navGridApi.sizeColumnsToFit();
    }
    onCellValueChanged(params: any) {
      if ('tas' === params.column.colId) {
        this.rowData[params.rowIndex].ias = params.data.tas;
      } else if ('ttrack' === params.column.colId) {
        this.rowData[params.rowIndex].ias = params.data.tas;
      } else if ('waypoint' === params.column.colId) {
          this.rowData[params.rowIndex].waypoint = params.data.waypoint;
      } else if ('tacvorndb' === params.column.colId) {
          this.rowData[params.rowIndex].tacvorndb = params.data.tacvorndb;
      } else if ('frequency' === params.column.colId) {
          this.rowData[params.rowIndex].frequency = params.data.frequency;
      } else if ('raddist' === params.column.colId) {
          this.rowData[params.rowIndex].raddist = params.data.raddist;
      }
      this.recalculate();
      params.api.refreshCells();
    }

    onWindChanged(params: FlightInfo) {
      this.wind = params;
      this.navplan.winddirection = params.winddirection;
      this.navplan.windspeed = params.windspeed;
      this.recalculate();
      const api = this.navGridApi;
      api.refreshCells();
    }

    recalculate() {
      let rowIndex = 0;
      this.rowData.forEach(element =>  {
        if (!(typeof this.wind === 'undefined')
          && !(typeof this.wind.winddirection === 'undefined')
          && !(typeof this.wind.windspeed === 'undefined')) {
          if (!(typeof element.tas === 'undefined')
           && !(typeof element.ttrack === 'undefined')) {
            // ontbind wind in 2 componenten, dwars op de true track en gelijk aan de true track
            const crosswind = this.getCrosswindComponent(this.wind, element.ttrack);
            const tailwind = this.getTailwindComponent(this.wind, element.ttrack);
            element.groundspeed = Math.round(parseFloat(element.tas.toString()) - tailwind);
            this.rowData[rowIndex].groundspeed = element.groundspeed;
            const correctionAngle = 60 / parseFloat(element.tas.toString()) * crosswind;
            this.rowData[rowIndex].wcangle = Math.round(correctionAngle);
            this.rowData[rowIndex].theading = Math.round(parseFloat(this.rowData[rowIndex].ttrack.toString()) + correctionAngle);
            if (typeof this.rowData[rowIndex].variation === 'undefined') {
              this.rowData[rowIndex].variation = 0;
            }
            this.rowData[rowIndex].mheading = parseFloat(this.rowData[rowIndex].theading.toString())
            + parseFloat(this.rowData[rowIndex].variation.toString());
            if (typeof this.rowData[rowIndex].deviation === 'undefined' ) {
              this.rowData[rowIndex].deviation = 0;
            }
            this.rowData[rowIndex].cheading = parseFloat(this.rowData[rowIndex].mheading.toString())
            + parseFloat(this.rowData[rowIndex].deviation.toString());

          }
        }
        if (rowIndex === 1) {
            this.rowData[rowIndex].tdist = this.rowData[rowIndex].idist;
            if (!(typeof this.rowData[rowIndex].groundspeed === 'undefined')) {
                this.rowData[rowIndex].itime = Math.round(parseFloat(this.rowData[rowIndex].idist.toString())
                * (60 / parseFloat(this.rowData[rowIndex].groundspeed.toString())));
                this.rowData[rowIndex].ttime = this.rowData[rowIndex].itime;
            }
        }
        if (rowIndex > 1) {
            if (!(typeof this.rowData[rowIndex].idist === 'undefined')
                && !(typeof this.rowData[rowIndex - 1].tdist === 'undefined')) {
            this.rowData[rowIndex].tdist = parseFloat(this.rowData[rowIndex - 1].tdist.toString())
                                            + parseFloat(this.rowData[rowIndex].idist.toString());
            }
            if (!(typeof this.rowData[rowIndex].groundspeed === 'undefined')) {
                this.rowData[rowIndex].itime = Math.round(parseFloat(this.rowData[rowIndex].idist.toString())
                * (60 / parseFloat(this.rowData[rowIndex].groundspeed.toString())));
                this.rowData[rowIndex].ttime = parseFloat(this.rowData[rowIndex - 1].ttime.toString())
                + parseFloat(this.rowData[rowIndex].itime.toString());
            }
        }
        rowIndex++;
      });
      this.createNavPlan();
    }
    
    createNavPlan() {       	
        var cnt = 0;
        this.rowData.forEach(datarow =>  {
            const navrow = fpboard.NavRow.create();
            navrow.cheading = datarow.cheading;
            navrow.deviation = datarow.deviation;
            navrow.frequency = datarow.frequency;
            navrow.groundspeed = datarow.groundspeed;
            navrow.ias = datarow.ias;
            navrow.idist = datarow.idist;
            navrow.itime = datarow.itime;
            navrow.mheading = datarow.mheading;
            navrow.moca = datarow.moca;
            navrow.order = cnt;
            navrow.raddist = datarow.raddist;
            navrow.tacvorndb = datarow.tacvorndb;
            navrow.tas = datarow.tas;
            navrow.tdist = datarow.tdist;
            navrow.theading = datarow.theading;
            navrow.ttime = datarow.ttime;
            navrow.ttrack = datarow.ttrack;
            navrow.variation = datarow.variation;
            navrow.waypoint = datarow.waypoint;
            navrow.wcangle = datarow.wcangle;
            this.navplan.navrows[cnt] = navrow;
            cnt++;
        });
       this.dataservice.setNavPlan(this.navplan);
    }
    getCrosswindComponent(wind: FlightInfo, truetrack: number): number {
      return Math.sin(this.degreesToRads(wind.winddirection - truetrack)) * wind.windspeed;
    }

    getTailwindComponent(wind: FlightInfo, truetrack: number): number {
      return Math.cos(this.degreesToRads(wind.winddirection - truetrack)) * wind.windspeed;
    }
}
