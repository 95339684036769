export class Navplanrow {
  itime: number;
  ttime: number;
  waypoint: string;
  tacvorndb: string;
  frequency: string;
  raddist: number;
  cheading: number;
  deviation: number;
  mheading: number;
  variation: number;
  theading: number;
  wcangle: number;
  ttrack: number;
  idist: number;
  tdist: number;
  groundspeed: number;
  tas: number;
  ias: number;
  moca: number;
}
