<mat-toolbar>
    <span>{{title}}</span>
    <span class="fill-remaining-space"></span>
    <span>
        <button (click)="onSync()" mat-icon-button>
            <mat-icon>sync</mat-icon>
        </button>
        <button (click)="onPrint()" mat-icon-button>
            <mat-icon>print</mat-icon>
        </button>
    </span>
</mat-toolbar>
<nav mat-tab-nav-bar>
  <a mat-tab-link
     *ngFor="let link of navLinks"
     [routerLink]="link.path"
     routerLinkActive #rla="routerLinkActive"
     [active]="rla.isActive">
    {{link.label}}
  </a>
</nav>
<router-outlet></router-outlet>