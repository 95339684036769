<app-header></app-header>
<ag-grid-angular
    class="ag-theme-material"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    (cellValueChanged)="onCellValueChanged($event)"
    (gridReady)="onGridReady($event)"
    (gridSizeChanged)="onGridSizeChanged($event)"
    >
</ag-grid-angular>
<app-footer (windchange)="onWindChanged($event)"></app-footer>