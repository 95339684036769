import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FlightInfo } from './flightinfo';
import { DataService } from '../../data.service';
import { fpboard } from '../../plan';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  title = 'PlanInfo';
  @Output() windchange: EventEmitter<FlightInfo> = new EventEmitter<FlightInfo>();

  private dataservice: DataService;
  private navplan : fpboard.Navplan;

  model = new FlightInfo(0, 0);
  constructor(dataservice: DataService) {
	  this.dataservice = dataservice;
	  this.navplan = this.dataservice.getNavPlan();
      
  }

  ngOnInit() {
	  if(!(this.navplan.windspeed===undefined)){
	      	this.model.windspeed = this.navplan.windspeed;
      }
      if(!(this.navplan.winddirection===undefined)){
      	this.model.winddirection = this.navplan.winddirection;
      }
  }
  
  onSpeedChange(speedvalue: number): void {
    this.model.windspeed = speedvalue;
    this.windchange.emit(this.model);
  }
  
  onDirectionChange(directionvalue: number): void {
    this.model.winddirection = directionvalue;
    this.windchange.emit(this.model);
  }
}
