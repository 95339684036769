import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { fpboard } from '../../plan';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  subscription: Subscription;
  private gridApi: any;
  private navplan : fpboard.Navplan;
  private dataservice: DataService;

  constructor(dataservice: DataService) {
	  this.dataservice = dataservice;
	       this.subscription = dataservice.printDomlayoutChanged$.subscribe(
            prindom => {
              if (prindom === 'print') {
                  this.gridApi.setColumnDefs(this.printcolumnDefs);
              } else {
                  this.gridApi.setColumnDefs(this.columnDefs);
              }
              this.gridApi.setDomLayout(prindom);
            }
        );
        this.navplan = this.dataservice.getNavPlan();
        if(!(this.navplan.callsign===undefined)){
        	this.rowData[0].areg = this.navplan.callsign;
        }
        if(!(this.navplan.planetype===undefined)){
        	this.rowData[0].atype = this.navplan.planetype;
        }
        if(!(this.navplan.flightdate===undefined) ){
        	this.rowData[0].fdate = this.navplan.flightdate;
        }
        if(!(this.navplan.fromairodrome===undefined)){
        	this.rowData[0].from = this.navplan.fromairodrome;
        }
        if(!(this.navplan.toairodrome===undefined)){
        	this.rowData[0].to = this.navplan.toairodrome;
        }
        if(!(this.navplan.pic===undefined)){
        	this.rowData[0].cname = this.navplan.pic;
        }
    }
  title = 'PlanInfo';
  columnDefs = [
       {headerName: 'Registration', headerTooltip: 'Aircraft Registration', field: 'areg', editable : true},
       {headerName: 'Type', headerTooltip: 'Aircraft Type', field: 'atype', editable : true },
       {headerName: 'Date', headerTooltip: 'Flight Date', field: 'fdate', editable : true },
       {headerName: 'From', headerTooltip: 'From', field: 'from', editable : true },
       {headerName: 'To', headerTooltip: 'To', field: 'to',  editable : true },
       {headerName: 'Captain', headerTooltip: 'Captain Name', field: 'cname', editable : true },
  ];
  printcolumnDefs = [
       {headerName: 'Registration', headerTooltip: 'Aircraft Registration', width : 170, field: 'areg'},
       {headerName: 'Type', headerTooltip: 'Aircraft Type', width : 170, field: 'atype'},
       {headerName: 'Date', headerTooltip: 'Flight Date', width : 170, field: 'fdate'},
       {headerName: 'From', headerTooltip: 'From', width : 170, field: 'from'},
       {headerName: 'To', headerTooltip: 'To', width : 170, field: 'to'},
       {headerName: 'Captain', headerTooltip: 'Captain Name', width : 170, field: 'cname'},
  ];
    rowData = [
        { areg: 'PH-', atype: '', fdate: '', from: '', to: '', cname: '' }
    ];

  onGridReady(params: { api: any; }) {
    this.gridApi = params.api;
    //todo remove after debug
    // this.gridApi.setDomLayout('print');
  }
  onCellValueChanged(params: any) {
	  if ('areg' === params.column.colId) {
		  this.navplan.callsign =  params.data.areg;
	  }
	  if ('atype' === params.column.colId) {
		  this.navplan.planetype =  params.data.atype;
	  }
	  if ('fdate' === params.column.colId) {
		  this.navplan.flightdate =  params.data.fdate;
	  }
	  if ('from' === params.column.colId) {
		  this.navplan.fromairodrome =  params.data.from;
	  }
	  if ('to' === params.column.colId) {
		  this.navplan.toairodrome =  params.data.to;
	  }
	  if ('cname' === params.column.colId) {
		  this.navplan.pic =  params.data.cname;
	  }
	  this.dataservice.setNavPlan(this.navplan);
  }
  
  ngOnInit() {
  }

}
