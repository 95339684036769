<mat-grid-list cols="2" rowHeight="2:1">
  <mat-grid-tile>
  	<mat-card>
  	  <mat-card-header>
    	<mat-card-title>Fuel (liters)</mat-card-title>
      </mat-card-header>
      <mat-card-content>
  		<form class="fuel-form">
  			<mat-form-field>
	  			 <mat-label>Taxi</mat-label>
	  			 <input matInput value="4"  id="taxifuel" name="taxi">
	  		</mat-form-field>
	  		<br/>
	  		<mat-form-field>
	  			 <mat-label>Trip plus 5%</mat-label>
	  			 <input matInput placeholder="Fuel for takeof, climb, cruise, decent, approach and landing + 5%" id="tripfuel" name="trip">
	  		</mat-form-field>
	  		<br/>
	  		<mat-form-field>
	  			 <mat-label>Holding 45 min</mat-label>
	  			 <input matInput placeholder="Fuel holding 45 minutes" id="holdingfuel" name="holding">
	  		</mat-form-field>
	  		<br/>
	  		<mat-form-field>
	  		<mat-label>Alternate</mat-label>
	  			 <input matInput placeholder="Fuel to fly from destination to alternate" id="alternatefuel" name="alternate">
	  		</mat-form-field>
	  		<br/>
	  		<mat-form-field>
	  			 <mat-label>Required</mat-label>
	  			 <input matInput placeholder="Required Fuel" id="requiredfuel" name="required">
	  		</mat-form-field>
	  		<br/>
	  		<mat-form-field>
	  			 <mat-label>Extra</mat-label>
	  			 <input matInput placeholder="Extra Fuel" id="extrafuel" name="extra">
	  		</mat-form-field>
	  		<br/>
	  		<mat-form-field>
	  		<mat-label>Block Fuel</mat-label>
	  			 <input matInput placeholder="Total Fuel">
	  		</mat-form-field>
  		</form>
  	  </mat-card-content>
  	</mat-card>
  </mat-grid-tile>
  <mat-grid-tile><mat-card>Simple card 2</mat-card></mat-grid-tile>
  <mat-grid-tile><mat-card>Simple card 3</mat-card></mat-grid-tile>
  <mat-grid-tile><mat-card>Simple card 4</mat-card></mat-grid-tile>
</mat-grid-list>
