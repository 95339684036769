import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { fpboard } from './plan';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }
  private domlayout = new Subject<string>();
  private navplan = new fpboard.Navplan();
  private complan = new fpboard.Complan();
  printDomlayoutChanged$ = this.domlayout.asObservable();

  printDomlayoutChanged(layout: string) {
    this.domlayout.next(layout);
  }

  setNavPlan(np: fpboard.Navplan) {
	console.log( "setNavPlan("+np+")");
    this.navplan = np;
  }
  
  getNavPlan() {
	return this.navplan;
  }
  
  setComPlan(cp: fpboard.Complan) {
	console.log( "setComPlan("+cp+")");
	this.complan = cp;
  }
	  
  getComPlan() {
	return this.complan;
  }
}
