/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.fpboard = (function() {

    /**
     * Namespace fpboard.
     * @exports fpboard
     * @namespace
     */
    var fpboard = {};

    fpboard.Plan = (function() {

        /**
         * Properties of a Plan.
         * @memberof fpboard
         * @interface IPlan
         * @property {string|null} [version] Plan version
         * @property {fpboard.INavplan|null} [navplan] Plan navplan
         * @property {Array.<fpboard.IComplan>|null} [complan] Plan complan
         */

        /**
         * Constructs a new Plan.
         * @memberof fpboard
         * @classdesc Represents a Plan.
         * @implements IPlan
         * @constructor
         * @param {fpboard.IPlan=} [properties] Properties to set
         */
        function Plan(properties) {
            this.complan = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Plan version.
         * @member {string} version
         * @memberof fpboard.Plan
         * @instance
         */
        Plan.prototype.version = "";

        /**
         * Plan navplan.
         * @member {fpboard.INavplan|null|undefined} navplan
         * @memberof fpboard.Plan
         * @instance
         */
        Plan.prototype.navplan = null;

        /**
         * Plan complan.
         * @member {Array.<fpboard.IComplan>} complan
         * @memberof fpboard.Plan
         * @instance
         */
        Plan.prototype.complan = $util.emptyArray;

        /**
         * Creates a new Plan instance using the specified properties.
         * @function create
         * @memberof fpboard.Plan
         * @static
         * @param {fpboard.IPlan=} [properties] Properties to set
         * @returns {fpboard.Plan} Plan instance
         */
        Plan.create = function create(properties) {
            return new Plan(properties);
        };

        /**
         * Encodes the specified Plan message. Does not implicitly {@link fpboard.Plan.verify|verify} messages.
         * @function encode
         * @memberof fpboard.Plan
         * @static
         * @param {fpboard.IPlan} message Plan message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Plan.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.version);
            if (message.navplan != null && Object.hasOwnProperty.call(message, "navplan"))
                $root.fpboard.Navplan.encode(message.navplan, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.complan != null && message.complan.length)
                for (var i = 0; i < message.complan.length; ++i)
                    $root.fpboard.Complan.encode(message.complan[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Plan message, length delimited. Does not implicitly {@link fpboard.Plan.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fpboard.Plan
         * @static
         * @param {fpboard.IPlan} message Plan message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Plan.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Plan message from the specified reader or buffer.
         * @function decode
         * @memberof fpboard.Plan
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fpboard.Plan} Plan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Plan.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fpboard.Plan();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.version = reader.string();
                    break;
                case 2:
                    message.navplan = $root.fpboard.Navplan.decode(reader, reader.uint32());
                    break;
                case 3:
                    if (!(message.complan && message.complan.length))
                        message.complan = [];
                    message.complan.push($root.fpboard.Complan.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Plan message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fpboard.Plan
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fpboard.Plan} Plan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Plan.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Plan message.
         * @function verify
         * @memberof fpboard.Plan
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Plan.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isString(message.version))
                    return "version: string expected";
            if (message.navplan != null && message.hasOwnProperty("navplan")) {
                var error = $root.fpboard.Navplan.verify(message.navplan);
                if (error)
                    return "navplan." + error;
            }
            if (message.complan != null && message.hasOwnProperty("complan")) {
                if (!Array.isArray(message.complan))
                    return "complan: array expected";
                for (var i = 0; i < message.complan.length; ++i) {
                    var error = $root.fpboard.Complan.verify(message.complan[i]);
                    if (error)
                        return "complan." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Plan message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fpboard.Plan
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fpboard.Plan} Plan
         */
        Plan.fromObject = function fromObject(object) {
            if (object instanceof $root.fpboard.Plan)
                return object;
            var message = new $root.fpboard.Plan();
            if (object.version != null)
                message.version = String(object.version);
            if (object.navplan != null) {
                if (typeof object.navplan !== "object")
                    throw TypeError(".fpboard.Plan.navplan: object expected");
                message.navplan = $root.fpboard.Navplan.fromObject(object.navplan);
            }
            if (object.complan) {
                if (!Array.isArray(object.complan))
                    throw TypeError(".fpboard.Plan.complan: array expected");
                message.complan = [];
                for (var i = 0; i < object.complan.length; ++i) {
                    if (typeof object.complan[i] !== "object")
                        throw TypeError(".fpboard.Plan.complan: object expected");
                    message.complan[i] = $root.fpboard.Complan.fromObject(object.complan[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Plan message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fpboard.Plan
         * @static
         * @param {fpboard.Plan} message Plan
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Plan.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.complan = [];
            if (options.defaults) {
                object.version = "";
                object.navplan = null;
            }
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            if (message.navplan != null && message.hasOwnProperty("navplan"))
                object.navplan = $root.fpboard.Navplan.toObject(message.navplan, options);
            if (message.complan && message.complan.length) {
                object.complan = [];
                for (var j = 0; j < message.complan.length; ++j)
                    object.complan[j] = $root.fpboard.Complan.toObject(message.complan[j], options);
            }
            return object;
        };

        /**
         * Converts this Plan to JSON.
         * @function toJSON
         * @memberof fpboard.Plan
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Plan.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Plan;
    })();

    fpboard.Navplan = (function() {

        /**
         * Properties of a Navplan.
         * @memberof fpboard
         * @interface INavplan
         * @property {number|null} [winddirection] Navplan winddirection
         * @property {number|null} [windspeed] Navplan windspeed
         * @property {string|null} [callsign] Navplan callsign
         * @property {string|null} [planetype] Navplan planetype
         * @property {string|null} [flightdate] Navplan flightdate
         * @property {string|null} [fromairodrome] Navplan fromairodrome
         * @property {string|null} [toairodrome] Navplan toairodrome
         * @property {string|null} [pic] Navplan pic
         * @property {Array.<fpboard.INavRow>|null} [navrows] Navplan navrows
         */

        /**
         * Constructs a new Navplan.
         * @memberof fpboard
         * @classdesc Represents a Navplan.
         * @implements INavplan
         * @constructor
         * @param {fpboard.INavplan=} [properties] Properties to set
         */
        function Navplan(properties) {
            this.navrows = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Navplan winddirection.
         * @member {number} winddirection
         * @memberof fpboard.Navplan
         * @instance
         */
        Navplan.prototype.winddirection = 0;

        /**
         * Navplan windspeed.
         * @member {number} windspeed
         * @memberof fpboard.Navplan
         * @instance
         */
        Navplan.prototype.windspeed = 0;

        /**
         * Navplan callsign.
         * @member {string} callsign
         * @memberof fpboard.Navplan
         * @instance
         */
        Navplan.prototype.callsign = "";

        /**
         * Navplan planetype.
         * @member {string} planetype
         * @memberof fpboard.Navplan
         * @instance
         */
        Navplan.prototype.planetype = "";

        /**
         * Navplan flightdate.
         * @member {string} flightdate
         * @memberof fpboard.Navplan
         * @instance
         */
        Navplan.prototype.flightdate = "";

        /**
         * Navplan fromairodrome.
         * @member {string} fromairodrome
         * @memberof fpboard.Navplan
         * @instance
         */
        Navplan.prototype.fromairodrome = "";

        /**
         * Navplan toairodrome.
         * @member {string} toairodrome
         * @memberof fpboard.Navplan
         * @instance
         */
        Navplan.prototype.toairodrome = "";

        /**
         * Navplan pic.
         * @member {string} pic
         * @memberof fpboard.Navplan
         * @instance
         */
        Navplan.prototype.pic = "";

        /**
         * Navplan navrows.
         * @member {Array.<fpboard.INavRow>} navrows
         * @memberof fpboard.Navplan
         * @instance
         */
        Navplan.prototype.navrows = $util.emptyArray;

        /**
         * Creates a new Navplan instance using the specified properties.
         * @function create
         * @memberof fpboard.Navplan
         * @static
         * @param {fpboard.INavplan=} [properties] Properties to set
         * @returns {fpboard.Navplan} Navplan instance
         */
        Navplan.create = function create(properties) {
            return new Navplan(properties);
        };

        /**
         * Encodes the specified Navplan message. Does not implicitly {@link fpboard.Navplan.verify|verify} messages.
         * @function encode
         * @memberof fpboard.Navplan
         * @static
         * @param {fpboard.INavplan} message Navplan message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Navplan.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.winddirection != null && Object.hasOwnProperty.call(message, "winddirection"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.winddirection);
            if (message.windspeed != null && Object.hasOwnProperty.call(message, "windspeed"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.windspeed);
            if (message.callsign != null && Object.hasOwnProperty.call(message, "callsign"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.callsign);
            if (message.planetype != null && Object.hasOwnProperty.call(message, "planetype"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.planetype);
            if (message.flightdate != null && Object.hasOwnProperty.call(message, "flightdate"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.flightdate);
            if (message.fromairodrome != null && Object.hasOwnProperty.call(message, "fromairodrome"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.fromairodrome);
            if (message.toairodrome != null && Object.hasOwnProperty.call(message, "toairodrome"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.toairodrome);
            if (message.pic != null && Object.hasOwnProperty.call(message, "pic"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.pic);
            if (message.navrows != null && message.navrows.length)
                for (var i = 0; i < message.navrows.length; ++i)
                    $root.fpboard.NavRow.encode(message.navrows[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Navplan message, length delimited. Does not implicitly {@link fpboard.Navplan.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fpboard.Navplan
         * @static
         * @param {fpboard.INavplan} message Navplan message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Navplan.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Navplan message from the specified reader or buffer.
         * @function decode
         * @memberof fpboard.Navplan
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fpboard.Navplan} Navplan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Navplan.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fpboard.Navplan();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.winddirection = reader.double();
                    break;
                case 2:
                    message.windspeed = reader.double();
                    break;
                case 3:
                    message.callsign = reader.string();
                    break;
                case 4:
                    message.planetype = reader.string();
                    break;
                case 5:
                    message.flightdate = reader.string();
                    break;
                case 6:
                    message.fromairodrome = reader.string();
                    break;
                case 7:
                    message.toairodrome = reader.string();
                    break;
                case 8:
                    message.pic = reader.string();
                    break;
                case 9:
                    if (!(message.navrows && message.navrows.length))
                        message.navrows = [];
                    message.navrows.push($root.fpboard.NavRow.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Navplan message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fpboard.Navplan
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fpboard.Navplan} Navplan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Navplan.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Navplan message.
         * @function verify
         * @memberof fpboard.Navplan
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Navplan.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.winddirection != null && message.hasOwnProperty("winddirection"))
                if (typeof message.winddirection !== "number")
                    return "winddirection: number expected";
            if (message.windspeed != null && message.hasOwnProperty("windspeed"))
                if (typeof message.windspeed !== "number")
                    return "windspeed: number expected";
            if (message.callsign != null && message.hasOwnProperty("callsign"))
                if (!$util.isString(message.callsign))
                    return "callsign: string expected";
            if (message.planetype != null && message.hasOwnProperty("planetype"))
                if (!$util.isString(message.planetype))
                    return "planetype: string expected";
            if (message.flightdate != null && message.hasOwnProperty("flightdate"))
                if (!$util.isString(message.flightdate))
                    return "flightdate: string expected";
            if (message.fromairodrome != null && message.hasOwnProperty("fromairodrome"))
                if (!$util.isString(message.fromairodrome))
                    return "fromairodrome: string expected";
            if (message.toairodrome != null && message.hasOwnProperty("toairodrome"))
                if (!$util.isString(message.toairodrome))
                    return "toairodrome: string expected";
            if (message.pic != null && message.hasOwnProperty("pic"))
                if (!$util.isString(message.pic))
                    return "pic: string expected";
            if (message.navrows != null && message.hasOwnProperty("navrows")) {
                if (!Array.isArray(message.navrows))
                    return "navrows: array expected";
                for (var i = 0; i < message.navrows.length; ++i) {
                    var error = $root.fpboard.NavRow.verify(message.navrows[i]);
                    if (error)
                        return "navrows." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Navplan message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fpboard.Navplan
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fpboard.Navplan} Navplan
         */
        Navplan.fromObject = function fromObject(object) {
            if (object instanceof $root.fpboard.Navplan)
                return object;
            var message = new $root.fpboard.Navplan();
            if (object.winddirection != null)
                message.winddirection = Number(object.winddirection);
            if (object.windspeed != null)
                message.windspeed = Number(object.windspeed);
            if (object.callsign != null)
                message.callsign = String(object.callsign);
            if (object.planetype != null)
                message.planetype = String(object.planetype);
            if (object.flightdate != null)
                message.flightdate = String(object.flightdate);
            if (object.fromairodrome != null)
                message.fromairodrome = String(object.fromairodrome);
            if (object.toairodrome != null)
                message.toairodrome = String(object.toairodrome);
            if (object.pic != null)
                message.pic = String(object.pic);
            if (object.navrows) {
                if (!Array.isArray(object.navrows))
                    throw TypeError(".fpboard.Navplan.navrows: array expected");
                message.navrows = [];
                for (var i = 0; i < object.navrows.length; ++i) {
                    if (typeof object.navrows[i] !== "object")
                        throw TypeError(".fpboard.Navplan.navrows: object expected");
                    message.navrows[i] = $root.fpboard.NavRow.fromObject(object.navrows[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Navplan message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fpboard.Navplan
         * @static
         * @param {fpboard.Navplan} message Navplan
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Navplan.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.navrows = [];
            if (options.defaults) {
                object.winddirection = 0;
                object.windspeed = 0;
                object.callsign = "";
                object.planetype = "";
                object.flightdate = "";
                object.fromairodrome = "";
                object.toairodrome = "";
                object.pic = "";
            }
            if (message.winddirection != null && message.hasOwnProperty("winddirection"))
                object.winddirection = options.json && !isFinite(message.winddirection) ? String(message.winddirection) : message.winddirection;
            if (message.windspeed != null && message.hasOwnProperty("windspeed"))
                object.windspeed = options.json && !isFinite(message.windspeed) ? String(message.windspeed) : message.windspeed;
            if (message.callsign != null && message.hasOwnProperty("callsign"))
                object.callsign = message.callsign;
            if (message.planetype != null && message.hasOwnProperty("planetype"))
                object.planetype = message.planetype;
            if (message.flightdate != null && message.hasOwnProperty("flightdate"))
                object.flightdate = message.flightdate;
            if (message.fromairodrome != null && message.hasOwnProperty("fromairodrome"))
                object.fromairodrome = message.fromairodrome;
            if (message.toairodrome != null && message.hasOwnProperty("toairodrome"))
                object.toairodrome = message.toairodrome;
            if (message.pic != null && message.hasOwnProperty("pic"))
                object.pic = message.pic;
            if (message.navrows && message.navrows.length) {
                object.navrows = [];
                for (var j = 0; j < message.navrows.length; ++j)
                    object.navrows[j] = $root.fpboard.NavRow.toObject(message.navrows[j], options);
            }
            return object;
        };

        /**
         * Converts this Navplan to JSON.
         * @function toJSON
         * @memberof fpboard.Navplan
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Navplan.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Navplan;
    })();

    fpboard.NavRow = (function() {

        /**
         * Properties of a NavRow.
         * @memberof fpboard
         * @interface INavRow
         * @property {number|Long|null} [order] NavRow order
         * @property {number|Long|null} [itime] NavRow itime
         * @property {number|Long|null} [ttime] NavRow ttime
         * @property {string|null} [waypoint] NavRow waypoint
         * @property {string|null} [tacvorndb] NavRow tacvorndb
         * @property {string|null} [frequency] NavRow frequency
         * @property {number|null} [raddist] NavRow raddist
         * @property {number|null} [cheading] NavRow cheading
         * @property {number|null} [deviation] NavRow deviation
         * @property {number|null} [theading] NavRow theading
         * @property {number|null} [mheading] NavRow mheading
         * @property {number|null} [variation] NavRow variation
         * @property {number|null} [theading1] NavRow theading1
         * @property {number|null} [wcangle] NavRow wcangle
         * @property {number|null} [ttrack] NavRow ttrack
         * @property {number|null} [idist] NavRow idist
         * @property {number|null} [tdist] NavRow tdist
         * @property {number|null} [groundspeed] NavRow groundspeed
         * @property {number|null} [tas] NavRow tas
         * @property {number|null} [ias] NavRow ias
         * @property {number|null} [moca] NavRow moca
         */

        /**
         * Constructs a new NavRow.
         * @memberof fpboard
         * @classdesc Represents a NavRow.
         * @implements INavRow
         * @constructor
         * @param {fpboard.INavRow=} [properties] Properties to set
         */
        function NavRow(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NavRow order.
         * @member {number|Long} order
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.order = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * NavRow itime.
         * @member {number|Long} itime
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.itime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * NavRow ttime.
         * @member {number|Long} ttime
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.ttime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * NavRow waypoint.
         * @member {string} waypoint
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.waypoint = "";

        /**
         * NavRow tacvorndb.
         * @member {string} tacvorndb
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.tacvorndb = "";

        /**
         * NavRow frequency.
         * @member {string} frequency
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.frequency = "";

        /**
         * NavRow raddist.
         * @member {number} raddist
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.raddist = 0;

        /**
         * NavRow cheading.
         * @member {number} cheading
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.cheading = 0;

        /**
         * NavRow deviation.
         * @member {number} deviation
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.deviation = 0;

        /**
         * NavRow theading.
         * @member {number} theading
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.theading = 0;

        /**
         * NavRow mheading.
         * @member {number} mheading
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.mheading = 0;

        /**
         * NavRow variation.
         * @member {number} variation
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.variation = 0;

        /**
         * NavRow theading1.
         * @member {number} theading1
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.theading1 = 0;

        /**
         * NavRow wcangle.
         * @member {number} wcangle
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.wcangle = 0;

        /**
         * NavRow ttrack.
         * @member {number} ttrack
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.ttrack = 0;

        /**
         * NavRow idist.
         * @member {number} idist
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.idist = 0;

        /**
         * NavRow tdist.
         * @member {number} tdist
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.tdist = 0;

        /**
         * NavRow groundspeed.
         * @member {number} groundspeed
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.groundspeed = 0;

        /**
         * NavRow tas.
         * @member {number} tas
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.tas = 0;

        /**
         * NavRow ias.
         * @member {number} ias
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.ias = 0;

        /**
         * NavRow moca.
         * @member {number} moca
         * @memberof fpboard.NavRow
         * @instance
         */
        NavRow.prototype.moca = 0;

        /**
         * Creates a new NavRow instance using the specified properties.
         * @function create
         * @memberof fpboard.NavRow
         * @static
         * @param {fpboard.INavRow=} [properties] Properties to set
         * @returns {fpboard.NavRow} NavRow instance
         */
        NavRow.create = function create(properties) {
            return new NavRow(properties);
        };

        /**
         * Encodes the specified NavRow message. Does not implicitly {@link fpboard.NavRow.verify|verify} messages.
         * @function encode
         * @memberof fpboard.NavRow
         * @static
         * @param {fpboard.INavRow} message NavRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NavRow.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.order);
            if (message.itime != null && Object.hasOwnProperty.call(message, "itime"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.itime);
            if (message.ttime != null && Object.hasOwnProperty.call(message, "ttime"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.ttime);
            if (message.waypoint != null && Object.hasOwnProperty.call(message, "waypoint"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.waypoint);
            if (message.tacvorndb != null && Object.hasOwnProperty.call(message, "tacvorndb"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.tacvorndb);
            if (message.frequency != null && Object.hasOwnProperty.call(message, "frequency"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.frequency);
            if (message.raddist != null && Object.hasOwnProperty.call(message, "raddist"))
                writer.uint32(/* id 7, wireType 1 =*/57).double(message.raddist);
            if (message.cheading != null && Object.hasOwnProperty.call(message, "cheading"))
                writer.uint32(/* id 8, wireType 1 =*/65).double(message.cheading);
            if (message.deviation != null && Object.hasOwnProperty.call(message, "deviation"))
                writer.uint32(/* id 9, wireType 1 =*/73).double(message.deviation);
            if (message.theading != null && Object.hasOwnProperty.call(message, "theading"))
                writer.uint32(/* id 10, wireType 1 =*/81).double(message.theading);
            if (message.mheading != null && Object.hasOwnProperty.call(message, "mheading"))
                writer.uint32(/* id 11, wireType 1 =*/89).double(message.mheading);
            if (message.variation != null && Object.hasOwnProperty.call(message, "variation"))
                writer.uint32(/* id 12, wireType 1 =*/97).double(message.variation);
            if (message.theading1 != null && Object.hasOwnProperty.call(message, "theading1"))
                writer.uint32(/* id 13, wireType 1 =*/105).double(message.theading1);
            if (message.wcangle != null && Object.hasOwnProperty.call(message, "wcangle"))
                writer.uint32(/* id 14, wireType 1 =*/113).double(message.wcangle);
            if (message.ttrack != null && Object.hasOwnProperty.call(message, "ttrack"))
                writer.uint32(/* id 15, wireType 1 =*/121).double(message.ttrack);
            if (message.idist != null && Object.hasOwnProperty.call(message, "idist"))
                writer.uint32(/* id 16, wireType 1 =*/129).double(message.idist);
            if (message.tdist != null && Object.hasOwnProperty.call(message, "tdist"))
                writer.uint32(/* id 17, wireType 1 =*/137).double(message.tdist);
            if (message.groundspeed != null && Object.hasOwnProperty.call(message, "groundspeed"))
                writer.uint32(/* id 18, wireType 1 =*/145).double(message.groundspeed);
            if (message.tas != null && Object.hasOwnProperty.call(message, "tas"))
                writer.uint32(/* id 19, wireType 1 =*/153).double(message.tas);
            if (message.ias != null && Object.hasOwnProperty.call(message, "ias"))
                writer.uint32(/* id 20, wireType 1 =*/161).double(message.ias);
            if (message.moca != null && Object.hasOwnProperty.call(message, "moca"))
                writer.uint32(/* id 21, wireType 1 =*/169).double(message.moca);
            return writer;
        };

        /**
         * Encodes the specified NavRow message, length delimited. Does not implicitly {@link fpboard.NavRow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fpboard.NavRow
         * @static
         * @param {fpboard.INavRow} message NavRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NavRow.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NavRow message from the specified reader or buffer.
         * @function decode
         * @memberof fpboard.NavRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fpboard.NavRow} NavRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NavRow.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fpboard.NavRow();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.order = reader.int64();
                    break;
                case 2:
                    message.itime = reader.int64();
                    break;
                case 3:
                    message.ttime = reader.int64();
                    break;
                case 4:
                    message.waypoint = reader.string();
                    break;
                case 5:
                    message.tacvorndb = reader.string();
                    break;
                case 6:
                    message.frequency = reader.string();
                    break;
                case 7:
                    message.raddist = reader.double();
                    break;
                case 8:
                    message.cheading = reader.double();
                    break;
                case 9:
                    message.deviation = reader.double();
                    break;
                case 10:
                    message.theading = reader.double();
                    break;
                case 11:
                    message.mheading = reader.double();
                    break;
                case 12:
                    message.variation = reader.double();
                    break;
                case 13:
                    message.theading1 = reader.double();
                    break;
                case 14:
                    message.wcangle = reader.double();
                    break;
                case 15:
                    message.ttrack = reader.double();
                    break;
                case 16:
                    message.idist = reader.double();
                    break;
                case 17:
                    message.tdist = reader.double();
                    break;
                case 18:
                    message.groundspeed = reader.double();
                    break;
                case 19:
                    message.tas = reader.double();
                    break;
                case 20:
                    message.ias = reader.double();
                    break;
                case 21:
                    message.moca = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NavRow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fpboard.NavRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fpboard.NavRow} NavRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NavRow.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NavRow message.
         * @function verify
         * @memberof fpboard.NavRow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NavRow.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.order != null && message.hasOwnProperty("order"))
                if (!$util.isInteger(message.order) && !(message.order && $util.isInteger(message.order.low) && $util.isInteger(message.order.high)))
                    return "order: integer|Long expected";
            if (message.itime != null && message.hasOwnProperty("itime"))
                if (!$util.isInteger(message.itime) && !(message.itime && $util.isInteger(message.itime.low) && $util.isInteger(message.itime.high)))
                    return "itime: integer|Long expected";
            if (message.ttime != null && message.hasOwnProperty("ttime"))
                if (!$util.isInteger(message.ttime) && !(message.ttime && $util.isInteger(message.ttime.low) && $util.isInteger(message.ttime.high)))
                    return "ttime: integer|Long expected";
            if (message.waypoint != null && message.hasOwnProperty("waypoint"))
                if (!$util.isString(message.waypoint))
                    return "waypoint: string expected";
            if (message.tacvorndb != null && message.hasOwnProperty("tacvorndb"))
                if (!$util.isString(message.tacvorndb))
                    return "tacvorndb: string expected";
            if (message.frequency != null && message.hasOwnProperty("frequency"))
                if (!$util.isString(message.frequency))
                    return "frequency: string expected";
            if (message.raddist != null && message.hasOwnProperty("raddist"))
                if (typeof message.raddist !== "number")
                    return "raddist: number expected";
            if (message.cheading != null && message.hasOwnProperty("cheading"))
                if (typeof message.cheading !== "number")
                    return "cheading: number expected";
            if (message.deviation != null && message.hasOwnProperty("deviation"))
                if (typeof message.deviation !== "number")
                    return "deviation: number expected";
            if (message.theading != null && message.hasOwnProperty("theading"))
                if (typeof message.theading !== "number")
                    return "theading: number expected";
            if (message.mheading != null && message.hasOwnProperty("mheading"))
                if (typeof message.mheading !== "number")
                    return "mheading: number expected";
            if (message.variation != null && message.hasOwnProperty("variation"))
                if (typeof message.variation !== "number")
                    return "variation: number expected";
            if (message.theading1 != null && message.hasOwnProperty("theading1"))
                if (typeof message.theading1 !== "number")
                    return "theading1: number expected";
            if (message.wcangle != null && message.hasOwnProperty("wcangle"))
                if (typeof message.wcangle !== "number")
                    return "wcangle: number expected";
            if (message.ttrack != null && message.hasOwnProperty("ttrack"))
                if (typeof message.ttrack !== "number")
                    return "ttrack: number expected";
            if (message.idist != null && message.hasOwnProperty("idist"))
                if (typeof message.idist !== "number")
                    return "idist: number expected";
            if (message.tdist != null && message.hasOwnProperty("tdist"))
                if (typeof message.tdist !== "number")
                    return "tdist: number expected";
            if (message.groundspeed != null && message.hasOwnProperty("groundspeed"))
                if (typeof message.groundspeed !== "number")
                    return "groundspeed: number expected";
            if (message.tas != null && message.hasOwnProperty("tas"))
                if (typeof message.tas !== "number")
                    return "tas: number expected";
            if (message.ias != null && message.hasOwnProperty("ias"))
                if (typeof message.ias !== "number")
                    return "ias: number expected";
            if (message.moca != null && message.hasOwnProperty("moca"))
                if (typeof message.moca !== "number")
                    return "moca: number expected";
            return null;
        };

        /**
         * Creates a NavRow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fpboard.NavRow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fpboard.NavRow} NavRow
         */
        NavRow.fromObject = function fromObject(object) {
            if (object instanceof $root.fpboard.NavRow)
                return object;
            var message = new $root.fpboard.NavRow();
            if (object.order != null)
                if ($util.Long)
                    (message.order = $util.Long.fromValue(object.order)).unsigned = false;
                else if (typeof object.order === "string")
                    message.order = parseInt(object.order, 10);
                else if (typeof object.order === "number")
                    message.order = object.order;
                else if (typeof object.order === "object")
                    message.order = new $util.LongBits(object.order.low >>> 0, object.order.high >>> 0).toNumber();
            if (object.itime != null)
                if ($util.Long)
                    (message.itime = $util.Long.fromValue(object.itime)).unsigned = false;
                else if (typeof object.itime === "string")
                    message.itime = parseInt(object.itime, 10);
                else if (typeof object.itime === "number")
                    message.itime = object.itime;
                else if (typeof object.itime === "object")
                    message.itime = new $util.LongBits(object.itime.low >>> 0, object.itime.high >>> 0).toNumber();
            if (object.ttime != null)
                if ($util.Long)
                    (message.ttime = $util.Long.fromValue(object.ttime)).unsigned = false;
                else if (typeof object.ttime === "string")
                    message.ttime = parseInt(object.ttime, 10);
                else if (typeof object.ttime === "number")
                    message.ttime = object.ttime;
                else if (typeof object.ttime === "object")
                    message.ttime = new $util.LongBits(object.ttime.low >>> 0, object.ttime.high >>> 0).toNumber();
            if (object.waypoint != null)
                message.waypoint = String(object.waypoint);
            if (object.tacvorndb != null)
                message.tacvorndb = String(object.tacvorndb);
            if (object.frequency != null)
                message.frequency = String(object.frequency);
            if (object.raddist != null)
                message.raddist = Number(object.raddist);
            if (object.cheading != null)
                message.cheading = Number(object.cheading);
            if (object.deviation != null)
                message.deviation = Number(object.deviation);
            if (object.theading != null)
                message.theading = Number(object.theading);
            if (object.mheading != null)
                message.mheading = Number(object.mheading);
            if (object.variation != null)
                message.variation = Number(object.variation);
            if (object.theading1 != null)
                message.theading1 = Number(object.theading1);
            if (object.wcangle != null)
                message.wcangle = Number(object.wcangle);
            if (object.ttrack != null)
                message.ttrack = Number(object.ttrack);
            if (object.idist != null)
                message.idist = Number(object.idist);
            if (object.tdist != null)
                message.tdist = Number(object.tdist);
            if (object.groundspeed != null)
                message.groundspeed = Number(object.groundspeed);
            if (object.tas != null)
                message.tas = Number(object.tas);
            if (object.ias != null)
                message.ias = Number(object.ias);
            if (object.moca != null)
                message.moca = Number(object.moca);
            return message;
        };

        /**
         * Creates a plain object from a NavRow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fpboard.NavRow
         * @static
         * @param {fpboard.NavRow} message NavRow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NavRow.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.order = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.itime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.itime = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.ttime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.ttime = options.longs === String ? "0" : 0;
                object.waypoint = "";
                object.tacvorndb = "";
                object.frequency = "";
                object.raddist = 0;
                object.cheading = 0;
                object.deviation = 0;
                object.theading = 0;
                object.mheading = 0;
                object.variation = 0;
                object.theading1 = 0;
                object.wcangle = 0;
                object.ttrack = 0;
                object.idist = 0;
                object.tdist = 0;
                object.groundspeed = 0;
                object.tas = 0;
                object.ias = 0;
                object.moca = 0;
            }
            if (message.order != null && message.hasOwnProperty("order"))
                if (typeof message.order === "number")
                    object.order = options.longs === String ? String(message.order) : message.order;
                else
                    object.order = options.longs === String ? $util.Long.prototype.toString.call(message.order) : options.longs === Number ? new $util.LongBits(message.order.low >>> 0, message.order.high >>> 0).toNumber() : message.order;
            if (message.itime != null && message.hasOwnProperty("itime"))
                if (typeof message.itime === "number")
                    object.itime = options.longs === String ? String(message.itime) : message.itime;
                else
                    object.itime = options.longs === String ? $util.Long.prototype.toString.call(message.itime) : options.longs === Number ? new $util.LongBits(message.itime.low >>> 0, message.itime.high >>> 0).toNumber() : message.itime;
            if (message.ttime != null && message.hasOwnProperty("ttime"))
                if (typeof message.ttime === "number")
                    object.ttime = options.longs === String ? String(message.ttime) : message.ttime;
                else
                    object.ttime = options.longs === String ? $util.Long.prototype.toString.call(message.ttime) : options.longs === Number ? new $util.LongBits(message.ttime.low >>> 0, message.ttime.high >>> 0).toNumber() : message.ttime;
            if (message.waypoint != null && message.hasOwnProperty("waypoint"))
                object.waypoint = message.waypoint;
            if (message.tacvorndb != null && message.hasOwnProperty("tacvorndb"))
                object.tacvorndb = message.tacvorndb;
            if (message.frequency != null && message.hasOwnProperty("frequency"))
                object.frequency = message.frequency;
            if (message.raddist != null && message.hasOwnProperty("raddist"))
                object.raddist = options.json && !isFinite(message.raddist) ? String(message.raddist) : message.raddist;
            if (message.cheading != null && message.hasOwnProperty("cheading"))
                object.cheading = options.json && !isFinite(message.cheading) ? String(message.cheading) : message.cheading;
            if (message.deviation != null && message.hasOwnProperty("deviation"))
                object.deviation = options.json && !isFinite(message.deviation) ? String(message.deviation) : message.deviation;
            if (message.theading != null && message.hasOwnProperty("theading"))
                object.theading = options.json && !isFinite(message.theading) ? String(message.theading) : message.theading;
            if (message.mheading != null && message.hasOwnProperty("mheading"))
                object.mheading = options.json && !isFinite(message.mheading) ? String(message.mheading) : message.mheading;
            if (message.variation != null && message.hasOwnProperty("variation"))
                object.variation = options.json && !isFinite(message.variation) ? String(message.variation) : message.variation;
            if (message.theading1 != null && message.hasOwnProperty("theading1"))
                object.theading1 = options.json && !isFinite(message.theading1) ? String(message.theading1) : message.theading1;
            if (message.wcangle != null && message.hasOwnProperty("wcangle"))
                object.wcangle = options.json && !isFinite(message.wcangle) ? String(message.wcangle) : message.wcangle;
            if (message.ttrack != null && message.hasOwnProperty("ttrack"))
                object.ttrack = options.json && !isFinite(message.ttrack) ? String(message.ttrack) : message.ttrack;
            if (message.idist != null && message.hasOwnProperty("idist"))
                object.idist = options.json && !isFinite(message.idist) ? String(message.idist) : message.idist;
            if (message.tdist != null && message.hasOwnProperty("tdist"))
                object.tdist = options.json && !isFinite(message.tdist) ? String(message.tdist) : message.tdist;
            if (message.groundspeed != null && message.hasOwnProperty("groundspeed"))
                object.groundspeed = options.json && !isFinite(message.groundspeed) ? String(message.groundspeed) : message.groundspeed;
            if (message.tas != null && message.hasOwnProperty("tas"))
                object.tas = options.json && !isFinite(message.tas) ? String(message.tas) : message.tas;
            if (message.ias != null && message.hasOwnProperty("ias"))
                object.ias = options.json && !isFinite(message.ias) ? String(message.ias) : message.ias;
            if (message.moca != null && message.hasOwnProperty("moca"))
                object.moca = options.json && !isFinite(message.moca) ? String(message.moca) : message.moca;
            return object;
        };

        /**
         * Converts this NavRow to JSON.
         * @function toJSON
         * @memberof fpboard.NavRow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NavRow.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NavRow;
    })();

    fpboard.Complan = (function() {

        /**
         * Properties of a Complan.
         * @memberof fpboard
         * @interface IComplan
         * @property {Array.<fpboard.IComRow>|null} [comrows] Complan comrows
         */

        /**
         * Constructs a new Complan.
         * @memberof fpboard
         * @classdesc Represents a Complan.
         * @implements IComplan
         * @constructor
         * @param {fpboard.IComplan=} [properties] Properties to set
         */
        function Complan(properties) {
            this.comrows = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Complan comrows.
         * @member {Array.<fpboard.IComRow>} comrows
         * @memberof fpboard.Complan
         * @instance
         */
        Complan.prototype.comrows = $util.emptyArray;

        /**
         * Creates a new Complan instance using the specified properties.
         * @function create
         * @memberof fpboard.Complan
         * @static
         * @param {fpboard.IComplan=} [properties] Properties to set
         * @returns {fpboard.Complan} Complan instance
         */
        Complan.create = function create(properties) {
            return new Complan(properties);
        };

        /**
         * Encodes the specified Complan message. Does not implicitly {@link fpboard.Complan.verify|verify} messages.
         * @function encode
         * @memberof fpboard.Complan
         * @static
         * @param {fpboard.IComplan} message Complan message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Complan.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.comrows != null && message.comrows.length)
                for (var i = 0; i < message.comrows.length; ++i)
                    $root.fpboard.ComRow.encode(message.comrows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Complan message, length delimited. Does not implicitly {@link fpboard.Complan.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fpboard.Complan
         * @static
         * @param {fpboard.IComplan} message Complan message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Complan.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Complan message from the specified reader or buffer.
         * @function decode
         * @memberof fpboard.Complan
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fpboard.Complan} Complan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Complan.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fpboard.Complan();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.comrows && message.comrows.length))
                        message.comrows = [];
                    message.comrows.push($root.fpboard.ComRow.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Complan message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fpboard.Complan
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fpboard.Complan} Complan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Complan.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Complan message.
         * @function verify
         * @memberof fpboard.Complan
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Complan.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.comrows != null && message.hasOwnProperty("comrows")) {
                if (!Array.isArray(message.comrows))
                    return "comrows: array expected";
                for (var i = 0; i < message.comrows.length; ++i) {
                    var error = $root.fpboard.ComRow.verify(message.comrows[i]);
                    if (error)
                        return "comrows." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Complan message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fpboard.Complan
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fpboard.Complan} Complan
         */
        Complan.fromObject = function fromObject(object) {
            if (object instanceof $root.fpboard.Complan)
                return object;
            var message = new $root.fpboard.Complan();
            if (object.comrows) {
                if (!Array.isArray(object.comrows))
                    throw TypeError(".fpboard.Complan.comrows: array expected");
                message.comrows = [];
                for (var i = 0; i < object.comrows.length; ++i) {
                    if (typeof object.comrows[i] !== "object")
                        throw TypeError(".fpboard.Complan.comrows: object expected");
                    message.comrows[i] = $root.fpboard.ComRow.fromObject(object.comrows[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Complan message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fpboard.Complan
         * @static
         * @param {fpboard.Complan} message Complan
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Complan.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.comrows = [];
            if (message.comrows && message.comrows.length) {
                object.comrows = [];
                for (var j = 0; j < message.comrows.length; ++j)
                    object.comrows[j] = $root.fpboard.ComRow.toObject(message.comrows[j], options);
            }
            return object;
        };

        /**
         * Converts this Complan to JSON.
         * @function toJSON
         * @memberof fpboard.Complan
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Complan.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Complan;
    })();

    fpboard.ComRow = (function() {

        /**
         * Properties of a ComRow.
         * @memberof fpboard
         * @interface IComRow
         * @property {string|null} [text] ComRow text
         */

        /**
         * Constructs a new ComRow.
         * @memberof fpboard
         * @classdesc Represents a ComRow.
         * @implements IComRow
         * @constructor
         * @param {fpboard.IComRow=} [properties] Properties to set
         */
        function ComRow(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ComRow text.
         * @member {string} text
         * @memberof fpboard.ComRow
         * @instance
         */
        ComRow.prototype.text = "";

        /**
         * Creates a new ComRow instance using the specified properties.
         * @function create
         * @memberof fpboard.ComRow
         * @static
         * @param {fpboard.IComRow=} [properties] Properties to set
         * @returns {fpboard.ComRow} ComRow instance
         */
        ComRow.create = function create(properties) {
            return new ComRow(properties);
        };

        /**
         * Encodes the specified ComRow message. Does not implicitly {@link fpboard.ComRow.verify|verify} messages.
         * @function encode
         * @memberof fpboard.ComRow
         * @static
         * @param {fpboard.IComRow} message ComRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComRow.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
            return writer;
        };

        /**
         * Encodes the specified ComRow message, length delimited. Does not implicitly {@link fpboard.ComRow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fpboard.ComRow
         * @static
         * @param {fpboard.IComRow} message ComRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComRow.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ComRow message from the specified reader or buffer.
         * @function decode
         * @memberof fpboard.ComRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fpboard.ComRow} ComRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComRow.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fpboard.ComRow();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ComRow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fpboard.ComRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fpboard.ComRow} ComRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComRow.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ComRow message.
         * @function verify
         * @memberof fpboard.ComRow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ComRow.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.text != null && message.hasOwnProperty("text"))
                if (!$util.isString(message.text))
                    return "text: string expected";
            return null;
        };

        /**
         * Creates a ComRow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fpboard.ComRow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fpboard.ComRow} ComRow
         */
        ComRow.fromObject = function fromObject(object) {
            if (object instanceof $root.fpboard.ComRow)
                return object;
            var message = new $root.fpboard.ComRow();
            if (object.text != null)
                message.text = String(object.text);
            return message;
        };

        /**
         * Creates a plain object from a ComRow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fpboard.ComRow
         * @static
         * @param {fpboard.ComRow} message ComRow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ComRow.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.text = "";
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = message.text;
            return object;
        };

        /**
         * Converts this ComRow to JSON.
         * @function toJSON
         * @memberof fpboard.ComRow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ComRow.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ComRow;
    })();

    return fpboard;
})();

module.exports = $root;
